// Generated by Framer (a7c2675)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["SfxtzNZH8"];

const serializationHash = "framer-hy6Gq"

const variantClassNames = {SfxtzNZH8: "framer-v-1vfxh7i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "SfxtzNZH8", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vfxh7i", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"SfxtzNZH8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-z2htoj"} data-framer-name={"Arrow"} layout={"position"} layoutDependency={layoutDependency} layoutId={"VEeK2qcSA"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 8 0 C 8.552 0 9 0.448 9 1 L 9 12.586 L 14.293 7.293 C 14.683 6.902 15.317 6.902 15.707 7.293 C 16.098 7.683 16.098 8.317 15.707 8.707 L 8.707 15.707 C 8.317 16.098 7.683 16.098 7.293 15.707 L 0.293 8.707 C -0.098 8.317 -0.098 7.683 0.293 7.293 C 0.683 6.902 1.317 6.902 1.707 7.293 L 7 12.586 L 7 1 C 7 0.448 7.448 0 8 0 Z\" transform=\"translate(4 4) rotate(-90 8 8)\" fill=\"var(--token-96ef5b7b-ed6e-4a26-9b93-c0662014d76c, rgb(0, 0, 0)) /* {&quot;name&quot;:&quot;Primary/Text&quot;} */\"></path></svg>"} svgContentId={988971615} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-hy6Gq[data-border=\"true\"]::after, .framer-hy6Gq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hy6Gq.framer-3jpxk0, .framer-hy6Gq .framer-3jpxk0 { display: block; }", ".framer-hy6Gq.framer-1vfxh7i { height: 24px; overflow: hidden; position: relative; width: 24px; }", ".framer-hy6Gq .framer-z2htoj { flex: none; height: 24px; left: calc(50.00000000000002% - 24px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerbDeJHtWDD: React.ComponentType<Props> = withCSS(Component, css, "framer-hy6Gq") as typeof Component;
export default FramerbDeJHtWDD;

FramerbDeJHtWDD.displayName = "Component/Arrow";

FramerbDeJHtWDD.defaultProps = {height: 24, width: 24};

addFonts(FramerbDeJHtWDD, [])